import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor } from '@angular/forms';

import { FormItemBase } from '../dynamic-form/form-item-types';
import { ByClass } from '../../models';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class FormItemComponentBase
  implements ControlValueAccessor, OnDestroy
{
  formItem: FormItemBase;

  @Input()
  formItemStyle: { [key: string]: string };

  @Input()
  formItemClass: ByClass;

  @Input()
  showValidationMessages: boolean;

  abstract onChange: () => {};

  abstract onTouched: () => {};

  constructor() {}

  abstract writeValue(value: any): void;

  abstract registerOnChange(fn: any): void;

  abstract registerOnTouched(fn: any): void;

  abstract validate(c: AbstractControl);

  abstract updateView(): void;

  ngOnDestroy(): void {
    this.formItem?.onDestroy();
  }
}
